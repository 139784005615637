/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Component } from 'react';
import solarPanelImage1 from './images/solar-panel-1.jpeg';
import solarPanelImage2 from './images/solar-panel-2.jpeg';
import heroImage4 from './images/hero-4.jpeg';
import { scrollElementIntoView } from './js';

class Homepage extends Component {
  scrollToSection = (elementId) => {
    scrollElementIntoView(elementId);
  };

  render(){
    return (
      <div>
        {/* Hero/Dashboard Area */}
        <section>
          <div className="container py-5 index-forward">
            <div className="row">
              <div className="col-lg-3">
                <h3>Welcome to Tabothi Energy Solutions</h3>
                <p className="lead mt-3">Energy independence and security is a possibility within reasonable budget,
                  Tabothi
                  Energy
                  Solution is your reliable partner through this transition.</p>
                <ul className="list-inline mt-4">
                  <li className="list-inline-item"><a className="btn btn-sm btn-primary first-hero-btn" name="scroller"
                    onClick={() => { this.scrollToSection("services") }}>Our
                      Services</a></li>
                  <li className="list-inline-item"><a className="btn btn-sm btn-outline-primary" name="scroller"
                      onClick={() => { this.scrollToSection("about-us")}}>About
                      Us</a></li>
                </ul>
              </div>
              <div className="col-lg-9">
                <iframe width="100%" height="900" title="example-system" src="https://vrm.victronenergy.com/installation/114942/embed/90955437"></iframe>
              </div>
            </div>
          </div>
        </section>
        {/* Features Section */}
        <section className="py-5 bg-light">
          <a id="about-us"></a>
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1">
                <p className="h6 mb-1 text-uppercase text-primary mb-3">About Tabothi Energy Solutions (TES)</p>
                <h2 className="mb-2">Our Philosophy</h2>
                <p className="text-small text-muted">Is anchored on three principles:</p>
                <ol className="col ml-3 mb-4">
                  <li className="text-small text-muted mb-2">Let us see the sun differently, it is a source of
                    lifetime
                    energy</li>
                  <li className="text-small text-muted mb-2">Every roof has the potential (if enabled) to convert
                    solar
                    energy into electrical energy</li>
                  <li className="text-small text-muted mb-2">Individual households and business have the potential to
                    transition
                    to energy independence.</li>
                </ol>
                <div className="border-top"></div>
                <h2 className="mt-4">Our Goal</h2>
                <p className="text-small text-muted mb-4 mt-1">Is to design and install well thought through solar and
                  backup
                  energy
                  systems
                  that meets our client energy needs within budget and shortest period possible.</p>
                <h2 className="mt-4">What We Believe</h2>
                <p className="text-small text-muted mb-4 mt-1">We strongly believe that energy independence and security
                  is
                  not
                  just a function of investment in a solar solution but a combination of strategic investment,
                  technology
                  optimisation and behavioural changes. When implemented together they reduce system costs,
                  ultimately
                  resulting in a higher degree of autonomy.</p>
                <ul className="list-inline py-4 border-top border-bottom d-flex align-items-center">
                  <li className="list-inline-item px-4 mr-0">
                    <h5 className="mb-0">Reuben Nkomo</h5>
                    <p className="small font-weight-normal text-muted mb-0">Founder and CEO</p>
                  </li>
                  <li className="list-inline-item pl-4 border-left">
                    <h6 className="mb-0">Have a Question? Contact Us at</h6>
                    <p className="small font-weight-normal text-muted mb-0"><a href="mailto:enquiries@tabothi.co.za"
                        style={{color: 'inherit', textDecoration: 'underline'}}
                        className="remove-on-hover">enquiries@tabothi.co.za</a> or <a href="tel:+27835770881"
                        style={{color: 'inherit', textDecoration: 'underline'}} className="remove-on-hover">+27 83
                        577 0881</a>
                    </p>
                  </li>
                </ul>
                <div className="center-btn-on-mobile">
                  <a className="btn btn-outline-primary" href="about.html">Click Here To Learn More About Us</a>
                </div>
              </div>
              <div className="col-lg-5 ml-auto mb-4 mb-lg-0 order-1 order-lg-2">
                <div className="p-2 border rounded">
                  <img className="img-fluid rounded" src={solarPanelImage1} alt="" style={{height: 544}}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Services Section */}
        <section className="py-5">
          <a id="services"></a>
          <div className="container py-5 text-center">
            <header className="mb-5">
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <p className="h6 text-uppercase text-primary mb-0">Our services</p>
                  <h2>For all your Solar Energy Solutions</h2>
                </div>
              </div>
            </header>
            <div className="row align-items-stretch">
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="px-4 py-5 shadow h-100 rounded">
                  <i className="fas fa-solar-panel text-primary text-xxl mb-4"></i>
                  <h3 className="h5">Grid Tied Systems</h3>
                  <p className="text-small mb-0 text-muted"></p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="px-4 py-5 shadow h-100 rounded">
                  <i className="fas fa-tools text-primary text-xxl mb-4"></i>
                  <h3 className="h5">Grid Interactive Systems</h3>
                  <p className="text-small mb-0 text-muted"></p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="px-4 py-5 shadow h-100 rounded">
                  <i className="fas fa-battery-full text-primary text-xxl mb-4"></i>
                  <h3 className="h5">Backup Solutions</h3>
                  <p className="text-small mb-0 text-muted"></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Divider Section */}
        <section className="bg-cover bg-center" style={{ backgroundImage: `url(${heroImage4})` }}>
          <div className="primary-overlay py-5">
            <div className="overlay-content">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7 text-center text-lg-left mb-4 mb-lg-0">
                    <h2 className="text-white mb-2">Looking to transition to Solar Energy?
                    </h2>
                    <p className="text-white mb-0">We're Ready To Help You</p>
                  </div>
                  <div className="col-lg-3 ml-auto text-center text-lg-right"><a className="btn btn-outline-light"
                      href="contact.html">Contact Us Now</a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Features Section */}
        <section className="py-5 bg-light">
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-lg-4 pl-lg-5 pr-lg-0">
                <div className="bg-primary pr-lg-1 rounded">
                  <img className="img-fluid rounded" src={solarPanelImage2} alt=""/>
                </div>
              </div>
              <div className="col-lg-7 px-lg-0">
                <div className="p-4 p-md-5 shadow bg-white rounded">
                  <p className="h6 mb-1 text-uppercase text-primary mb-3">Our Business Process</p>
                  <p className="text-muted mb-3">There is no one size fit all with solar systems and back solutions,
                    our
                    system
                    design process, as
                    well as the selection of system components is based on client demonstrated energy usage,
                    desired goal,
                    and
                    affordability.
                    As such, our process entrails:</p>
                  <div className="row">
                    <div className="col-lg-9">
                      <ol className="mb-0 text-muted">
                        <li className="mb-4">
                          {/* <!-- <h5>We Get In Touch</h5> --> */}
                          <p className="text-lg text-muted">Load profiling and data analysis to determine:</p>
                          <ul>
                            <li>Base load</li>
                            <li>Maximum load, frequency, and duration</li>
                            <li>Opportunities for alternative source of energy</li>
                          </ul>
                        </li>
                        <li className="mb-4">
                          {/* <!-- <h5>We Come To You</h5> --> */}
                          <p className="text-lg text-muted">Load shifting and recommended behavioural changes
                          </p>
                        </li>
                        <li className="mb-4">
                          {/* <!-- <h5>Our Work is Quick &amp; Professional</h5> --> */}
                          <p className="text-lg text-muted">Comparative system designs and alternative
                            technological proposals
                          </p>
                        </li>
                        <li className="mb-4">
                          {/* <!-- <h5>Reasonable Prices</h5> --> */}
                          <p className="text-lg text-muted">Quality installation and commissioning</p>
                        </li>
                        <li>
                          {/* <!-- <h5>Reasonable Prices</h5> --> */}
                          <p className="text-lg text-muted">Monitoring, Maintenance, and recommended upgrades.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Homepage;
