/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Component } from 'react';
import Sunsynk1 from './images/sunsynk-1.png';
import Sunsynk2 from './images/sunsynk-2.png';
import SunsynkBanner from './images/sunsynk-banner.png';
import { goToSunsynkSlide, setCurrentSunsynkSlide, goToPreviousSunsynkSlide, goToNextSunsynkSlide } from './js';
import './SunSynkApprovedPage.css';

class SunSynkApprovedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 1,
    };
  }

  componentDidMount() {
    goToSunsynkSlide(this.state.slideIndex);
  }

  getSlidesData() {
    const slideIndex = this.state.slideIndex;
    const slides = document.getElementsByClassName('sunsynkSlide');
    const slidesLength = slides.length;
    return { slideIndex, slidesLength };
  }

  reduceSlideIndex() {
    const { slideIndex } = this.getSlidesData();
    let newSlideIndex = slideIndex - 1;
    this.setNewSlideIndex(newSlideIndex);
  }
  
  increaseSlideIndex() {
    const { slideIndex } = this.getSlidesData();
    let newSlideIndex = slideIndex + 1;
    this.setNewSlideIndex(newSlideIndex);
  }

  setNewSlideIndex(newSlideIndex) {
    const { slidesLength } = this.getSlidesData();
    if (newSlideIndex > slidesLength) { newSlideIndex = 1 }
    if (newSlideIndex < 1) { newSlideIndex = slidesLength }
    this.setState({ slideIndex: newSlideIndex });
  }

  render(){
    return (
      <div>
        {/* Slides Section */}
          <section>
            <div className="slideshow-container">
              <div className="sunsynkSlide">
                <div className="numbertext">1 / 2</div>
                <img src={Sunsynk1} alt='Sunsynk slide 1' style={{ width: '100%' }} />
              </div>

              <div className="sunsynkSlide">
                <div className="numbertext">2 / 2</div>
                <img src={Sunsynk2} alt='Sunsynk slide 2' style={{width: '100%'}} />
              </div>
              <a className="prev" onClick={() => { goToPreviousSunsynkSlide(this.state.slideIndex); this.reduceSlideIndex(); }}>&#10094;</a>
              <a className="next" onClick={() => { goToNextSunsynkSlide(this.state.slideIndex); this.increaseSlideIndex(); }}>&#10095;</a>
            </div>
            <br/>
            <div style={{ textAlign: 'center' }}>
              <span className="sunsynkSlideDot" onClick={ () => { setCurrentSunsynkSlide(1); this.setNewSlideIndex(1) }}></span>
              <span className="sunsynkSlideDot" onClick={ () => { setCurrentSunsynkSlide(2); this.setNewSlideIndex(2) }}></span>
            </div>
          </section>
          <section className='mt-4'>
            <img src={SunsynkBanner} alt='Sunsynk Banner' className='img-fluid w-100'/>
          </section>
        {/* End Slides Section */}
      </div>
    );
  }
}

export default SunSynkApprovedPage;
