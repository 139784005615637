/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import heroImage10 from './images/hero-10.jpg';
import heroImage8 from './images/hero-8.jpg';
import './RentalSolarPackages.css';
import SolarRentalPackages from './data/solar-rental-pakages.json';

class RentalSolarPackages extends Component {
  buildTitle(title) {
    const originalString = title;
    const indexOfBracket = originalString.indexOf('(');
    const bracketInString = indexOfBracket >= 0;
    title = bracketInString ? title.substring(0, indexOfBracket) : title;
    const splitTitle = title.split(' ');
    if (bracketInString) {
      const bracketSection = originalString.substring(indexOfBracket);
      splitTitle.push(bracketSection);
    }
    return splitTitle;
  }

  formatNumber(number) {
    // Custom format logic (e.g., add commas as thousand separators)
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render(){
    return (
      <div>
        {/* Hero Slider */}
        <section className="hero bg-cover bg-position py-4" style={{backgroundImage: `url(${heroImage10})`}}>
          <div className="container py-5 index-forward text-white">
            <h1>Rental Solar Packages</h1>
            {/* Breadcrumb */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-none mb-0 p-0">
                <li className="breadcrumb-item pl-0"><a href="/"> <i className="fas fa-home mr-2"></i>Home</a></li>
                <li className="breadcrumb-item" aria-current="page">Rental Solar Packages</li>
              </ol>
            </nav>
          </div>
        </section>
        {/* <!-- Services Section --> */}
        <section className="py-5">
          <div className="container py-5 text-center">
            <header className="mb-5">
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <p className="h6 text-uppercase text-primary mb-0">Rental Solar Packages</p>
                  <h2>Solar solutions designed to meet your energy needs</h2>
                </div>
              </div>
            </header>
            <div className="row align-items-stretch">
              {
                SolarRentalPackages.map((solarRentalPackage, index) => 
                  <div className="col-lg-4 col-md-6 mb-4" key={index}>
                    <div className="px-4 py-5 shadow h-100 rounded">
                      <div className='d-flex justify-content-center'>
                        <div className={solarRentalPackage.titleColor === 'purple' ? 'purple-header' : 'blue-header'}>
                          {
                            this.buildTitle(solarRentalPackage.title).map((titleSection, titleIndex) => 
                              <span key={titleIndex}>{titleSection}</span>
                            )
                          }
                        </div>
                      </div>
                      <div className='price'>R{this.formatNumber(solarRentalPackage.price)}<span className='price-slash'>/</span><span className='small-text'>month</span></div>
                      <div className='price-admin-fee'><span className='bold-text'>+ R{this.formatNumber(solarRentalPackage.adminFee)}</span> once-off admin fee</div>
                      <hr style={{ marginTop: '1.5rem', width: '85%' }} />
                      {solarRentalPackage.showAllInOneUnit &&
                        <div className='d-flex justify-content-center'>
                          <div className='unit-text'>
                            All in one unit
                          </div>
                        </div>
                      }
                      <div className='d-flex justify-content-center'>
                        <div className='package-info-block'>
                          {
                            solarRentalPackage.packageInfo.map((packageInfoLine, packageInfoLineIndex) => 
                              <div className='package-info' key={packageInfoLineIndex}><span className='bold-text'>{packageInfoLine.number}{packageInfoLine.unit.length ? ' ' + packageInfoLine.unit : ''}</span> {packageInfoLine.item}</div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </section>
        {/* Divider Section */}
        <section className="bg-cover bg-center" style={{backgroundImage: `url(${heroImage8})`}}>
          <div className="primary-overlay py-5">
            <div className="overlay-content">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7 text-center text-lg-left mb-4 mb-lg-0">
                    <h2 className="text-white mb-2">
                      Looking to rent a solar system?
                    </h2>
                    <p className="text-white mb-0">Tabothi Energy Solutions Is Here To Help You, visit the Rental Packages Website for more info</p>
                  </div>
                  <div className="col-lg-3 ml-auto text-center text-lg-right"><a className="btn btn-outline-light"
                    rel="noreferrer" target="_blank"
                    href="https://rechargerental.co.za/residential" >Rental Packages Website</a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default RentalSolarPackages;
