/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';

class PageNotFound extends Component {
  render(){
    return (
      <div>
        Page not found. <a href="/">Go to homepage</a>
      </div>
    );
  }
}

export default PageNotFound;
