/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import SunsynkBadge from './images/sunsynk-badge.png';

class Footer extends Component {
  openPreferencesCenter() {
    const openPreferencesCenterAnchor = document.getElementById('open_preferences_center');
    openPreferencesCenterAnchor.click();
  }

  render(){
    return (
      <div>
        <footer className="bg-dark">
          <div className="bg-dark py-5 text-white">
            <div className="container">
              <div className="row py-5">
                <div className="col-lg-6 col-md-6">
                  <p className="h2">
                    Tabothi Energy Solutions
                  </p>
                  <p className="text-muted text-small mb-4">See the sun differently.</p>
                  <p className="mb-1"><i className="fas fa-envelope mr-3 text-primary fa-fw"></i><span
                      className="text-small text-muted"><a className="footer-link" href="mailto:enquiries@tabothi.co.za"
                        target="_blank" rel="noreferrer">enquiries@tabothi.co.za</a></span></p>
                  <p className="mb-1"><i className="fas fa-mobile mr-3 text-primary fa-fw"></i><span className="text-small text-muted"><a
                        className="footer-link" href="tel:+27835770881" target="_blank" rel="noreferrer">+27 83
                        577 0881</a></span></p>
                  <div className='mb-1 d-flex justify-content-between'>
                    <a href='sunsynk-approved-installer.html'>
                      <img src={SunsynkBadge} alt='Sunsynk Badge' className='img-fluid w-25'/>
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <h5 className="mt-3 mb-4 font-weight-normal">Quick links</h5>
                  <ul className="list-unstyled">
                    <li className="mb-2"><a className="footer-link" href="/">Home</a></li>
                    <li className="mb-2"><a className="footer-link" href="about.html">About</a></li>
                    <li className="mb-2"><a className="footer-link" href="contact.html">Contact</a></li>
                    <li className="mb-2"><a className="footer-link" href="projects.html">Projects</a></li>
                    {/* <li className="mb-2"><a className="footer-link" href="products.html">Products</a></li> */}
                    <li className="mb-2"><a className="footer-link" href="rental-solar-packages.html">Rental Solar Packages</a></li>
                    <li className="mb-2"><a className="footer-link" href="sunsynk-approved-installer.html">Sunsynk</a></li>
                    <li className="mb-2"><a className="footer-link" href="privacy-policy.html">Privacy Policy</a></li>
                    <li className="mb-2"><a className="footer-link" onClick={() => this.openPreferencesCenter() }>Change your cookie preferences</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6">
                  <h5 className="mt-3 mb-4 font-weight-normal">Operating Hours</h5>
                  <p className="text-muted text-small mb-4">We're open Monday - Saturday!</p>
                  <p className="mb-1"><i className="fas fa-clock mr-3 text-primary fa-fw"></i><span className="text-small text-muted">Mon
                      - Fri: 9:00 -
                      16:00</span>
                  </p>
                  <p className="mb-1"><i className="fas fa-clock mr-3 text-primary fa-fw"></i><span className="text-small text-muted">Sat:
                      9:00 -
                      13:00</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-darker py-4">
            <div className="container text-center">
              <p className="mb-0 text-muted text-small">&copy; All rights reserved. Website made by <a className="attribution-link"
                  href="mailto:ralendigital@gmail.com?subject=I would like a website" target="_blank" rel="noreferrer">Ralen Digital</a> with Template by 
                <a className="attribution-link" href="https://bootstraptemple.com"> Bootstrap
                  Temple</a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
