/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import heroImage4 from './images/hero-4.jpeg';
import inverterImage1 from './images/inv-1.png';
import batteryImage1 from './images/batt-1.png';
import solarProductImage1 from './images/sp-1.png';
import inverterImage2 from './images/inv-2.png';
import batteryImage2 from './images/batt-2.png';
import solarProductImage2 from './images/sp-2.png';
import inverterImage3 from './images/inv-3.png';
import batteryImage3 from './images/batt-3.png';
import solarProductImage3 from './images/sp-3.png';
import inverterImage4 from './images/inv-4.png';
import batteryImage4 from './images/batt-4.png';
import solarProductImage4 from './images/sp-4.png';
import gasWaterHeatersImage1 from './images/gwh-1.png';
import gasWaterHeatersImage2 from './images/gwh-2.png';
import gasWaterHeatersImage3 from './images/gwh-3.png';
import gasWaterHeatersImage4 from './images/gwh-4.png';

class Products extends Component {
  render(){
    return (
      <div>
        <section className="hero bg-cover bg-position py-4" style={{backgroundImage: `url(${heroImage4})`}}>
          <div className="container py-5 index-forward text-white">
            <h1>Products</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-none mb-0 p-0">
                <li className="breadcrumb-item pl-0"><a href="/"> <i className="fas fa-home mr-2"></i>Home</a></li>
                <li className="breadcrumb-item" aria-current="page">Products</li>
              </ol>
            </nav>
          </div>
        </section>
        <section className="py-2">
          <div className="container py-5">
            <h2>Product Range</h2>
            <p className="text-muted mb-5">It is a fact that not all products are created equal, 
              as such, we specialise in four inverters brands and four battery storage systems 
              from reputable distributors and manufactures. We believe specialisation is key 
              to quality system design and best performance, hence the decision to focus on products 
              with manufactures approved compatibilities.
            </p>
            <h2 className="mt-3">Product Range</h2>
            <p className="text-muted mb-3">Our reputation and business sustainability are 
              intertwined between the products capabilities and our ability to design, 
              install, and manage them optimally.
            </p>
          </div>
        </section>
        <section className="py-2">
          <div className="container py-2">
            <h2 className="pb-2 mb-4">Tabothi Solar & Backup Product Range</h2>
            <div className="container border text-black">
              <div className="row border-bottom">
                <div className="col-4 py-2 border-right d-flex justify-content-center align-items-center text-center text-black">
                  <h2>Inverters</h2>
                </div>
                <div className="col-4 py-2 border-right d-flex justify-content-center align-items-center text-center text-black">
                  <h2>Batteries</h2>
                </div>
                <div className="col-4 py-2 d-flex justify-content-center align-items-center text-center text-black">
                  <h2>Solar Panels</h2>
                </div>
              </div>
              <div className="row border-bottom">
                <div className="col-4 border-right d-flex justify-content-center">
                  <img className="img-fluid" src={inverterImage1}/>
                </div>
                <div className="col-4 border-right d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={batteryImage1}/>
                </div>
                <div className="col-4 d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={solarProductImage1}/>
                </div>
              </div>
              <div className="row border-bottom">
                <div className="col-4 border-right d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={inverterImage2}/>
                </div>
                <div className="col-4 border-right d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={batteryImage2}/>
                </div>
                <div className="col-4 d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={solarProductImage2}/>
                </div>
              </div>
              <div className="row border-bottom">
                <div className="col-4 border-right d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={inverterImage3}/>
                </div>
                <div className="col-4 border-right d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={batteryImage3}/>
                </div>
                <div className="col-4 d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={solarProductImage3}/>
                </div>
              </div>
              <div className="row">
                <div className="col-4 border-right d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={inverterImage4}/>
                </div>
                <div className="col-4 border-right d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={batteryImage4}/>
                </div>
                <div className="col-4 d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={solarProductImage4}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-2">
          <div className="container py-5">
            <h2 className="pb-2 mb-4 mt-5">Tabothi Gas Water Heaters Product Range</h2>
            <div className="container border text-black">
              <div className="row border-bottom">
                <div className="col-6 border-right d-flex justify-content-center">
                  <img className="img-fluid" src={gasWaterHeatersImage1}/>
                </div>
                <div className="col-6 border-right d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={gasWaterHeatersImage2}/>
                </div>
              </div>
              <div className="row">
                <div className="col-6 border-right d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={gasWaterHeatersImage3}/>
                </div>
                <div className="col-6 border-right d-flex justify-content-center align-items-center">
                  <img className="img-fluid" src={gasWaterHeatersImage4}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-2 mt-0">
          <div className="container py-5">
            <h2>Frequently Asked Questions</h2>
            <p className="text-muted mb-5">See our most common asks.</p>
            <div className="row">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <div className="accordion" id="faqAccordion">
                  <div className="card rounded-0 border-0 mb-1 round-border">
                    <div className="card-header p-0 border-0 bg-white rounded-0" id="headingOne">
                      <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block" data-toggle="collapse"
                          href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          How Do I Get In Contact?
                        </a></h6>
                    </div>
                    <div className="collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#faqAccordion">
                      <div className="card-body">
                        <p className="text-small text-muted">Just contact us for services using our <a href="contact.html">contact
                            form</a> for any questions <a href="mailto:enquiries@tabothi.co.za">mail</a> or <a
                            href="tel:+27835770881">call</a> us.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card rounded-0 border-0 mb-1 round-border">
                    <div className="card-header p-0 border-0 bg-white rounded-0" id="headingTwo">
                      <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block collapsed" data-toggle="collapse"
                          href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          What Is Your Business Process
                        </a></h6>
                    </div>
                    <div className="collapse" id="collapseTwo" aria-labelledby="headingTwo" data-parent="#faqAccordion">
                      <div className="card-body">
                        <ol>
                          <li className="text-muted text-sm mb-4">
                            Load profiling and data analysis to determine:
                            <ul>
                              <li>Base load</li>
                              <li>Maximum load, frequency, and duration</li>
                              <li>Opportunities for alternative source of energy</li>
                            </ul>
                          </li>
                          <li className="text-muted text-sm mb-4">
                            Load shifting and recommended behavioural changes
                          </li>
                          <li className="text-muted text-sm mb-4">
                            Comparative system designs and alternative technological proposals
                          </li>
                          <li className="text-muted text-sm mb-4">
                            Quality installation and commissioning
                          </li>
                          <li className="text-muted text-sm">
                            Monitoring, Maintenance, and recommended upgrades.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="card rounded-0 border-0 round-border">
                    <div className="card-header p-0 border-0 bg-white rounded-0" id="headingThree">
                      <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block collapsed border-bottom"
                          data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          What Services Do You Offer?
                        </a></h6>
                    </div>
                    <div className="collapse" id="collapseThree" aria-labelledby="headingThree" data-parent="#faqAccordion">
                      <div className="card-body">
                        <ul>
                          <li className="text-muted">Grid Tied Systems</li>
                          <li className="text-muted">Grid Interactive Systems</li>
                          <li className="text-muted">Backup Solutions</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-cover bg-center" style={{backgroundImage: `url(${heroImage4})`}}>
          <div className="primary-overlay py-5">
            <div className="overlay-content">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7 text-center text-lg-left mb-4 mb-lg-0">
                    <h2 className="text-white mb-2">
                      Looking to transition to Solar Energy?
                    </h2>
                    <p className="text-white mb-0">Tabothi Energy Solutions Is Here To Help You</p>
                  </div>
                  <div className="col-lg-3 ml-auto text-center text-lg-right"><a className="btn btn-outline-light"
                      href="contact.html">Contact Us Now</a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Products;
