/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import companyLogo from './images/logo-4.jpeg';
import pageTitles from './data/page-titles.json';


class Header extends Component {
    constructor(props){
        super(props);
        this.state = { currentPath: `${document.location.pathname}` };
        const pageTitleWithExtensionAndLeadingSlashRemoved = this.state.currentPath.replace('/','').replace('.html', '');
        // Default is Page not found. If page found below then page title is updated.
        let pageTitle = 'Page Not Found';
        pageTitles.forEach(pageTitleObject => {
            if (pageTitleObject.pageUrlPaths.includes(pageTitleWithExtensionAndLeadingSlashRemoved)) 
                pageTitle = pageTitleObject.title;
        });
        document.title = `${pageTitle} | Tabothi Energy Solutions`;
    }

    render(){
        return (
          <div>
              <header className="header">
                  <div className="top-bar bg-dark text-white">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-8 text-right py-2 text-center text-lg-right ml-auto">
                                  <ul className="list-inline contact-header mb-0">
                                      <li className="list-inline-item"><a className="font-weight-normal text-small" href="tel:+27835770881"
                                          target="_blank" rel="noreferrer"><i className="fas fa-mobile mr-2"></i>+27 83 577
                                          0881</a></li>
                                      <li className="list-inline-item mid-bar">|</li>
                                      <li className="list-inline-item"><a className="font-weight-normal text-small"
                                          href="mailto:enquiries@tabothi.co.za" target="_blank" rel="noreferrer"><i
                                              className="fas fa-envelope mr-2"></i>enquiries@tabothi.co.za</a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  <nav className="navbar navbar-expand-xl navbar-light py-3 bg-white shadow-sm">
                      <div className="container">
                          <a className="navbar-brand" href="/">
                              <div className="row business-header">
                                  <img className="img-fluid mr-2" src={companyLogo} alt="Company Logo" width="50" style={{ height: 50 }}/>
                                  <p className="h2 business-name my-auto">
                                  Tabothi Energy Solutions
                                  </p>
                              </div>
                          </a>
                          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                          aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span>
                          </button>
                          <div className="collapse navbar-collapse" id="navbarSupportedContent">
                              <ul className="navbar-nav ml-auto">
                                <li className={this.state.currentPath === '/' || this.state.currentPath === '/index.html' ? "nav-item mx-2 active" : "nav-item mx-2"}>
                                    <a className="nav-link text-uppercase" href="/">Home</a>
                                </li>
                                <li className={this.state.currentPath === '/about.html' ? "nav-item mx-2 active" : "nav-item mx-2"}>
                                    <a className="nav-link text-uppercase" href="about.html">About</a>
                                </li>
                                {/* <li className={this.state.currentPath === '/projects.html' ? "nav-item mx-2 active" : "nav-item mx-2"}>
                                    <a className="nav-link text-uppercase" href="projects.html">Projects</a>
                                </li> */}
                                <li className={['/residential-projects.html', '/commercial-projects.html'].includes(this.state.currentPath) 
                                    ? "nav-item mx-2 dropdown active" : "nav-item mx-2 dropdown"}
                                >
                                    <a className="nav-link text-uppercase dropdown-toggle pr-0"
                                        id="projectsDropdownMenuLink" href="#" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        Projects
                                    </a>
                                    <div className="dropdown-menu mt-lg-4" aria-labelledby="projectsDropdownMenuLink">
                                        <a className="dropdown-item font-weight-bold text-small" href="residential-projects.html">Residential Projects</a>
                                        <a className="dropdown-item font-weight-bold text-small" href="commercial-projects.html">Commercial Projects</a>
                                    </div>
                                </li>
                                <li className={this.state.currentPath === '/rental-solar-packages.html' ? "nav-item active" : "nav-item"}>
                                    <a className="nav-link text-uppercase" href="rental-solar-packages.html">Rental Solar Packages</a>
                                </li>
                                {/* <li className={this.state.currentPath === '/products.html' ? "nav-item mx-2 active" : "nav-item mx-2"}>
                                    <a className="nav-link text-uppercase" href="products.html">Products</a>
                                </li> */}
                                <li className="nav-item pl-lg-3">
                                    <a className={this.state.currentPath === '/contact.html' ? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"} href="contact.html">Contact Us</a>
                                </li>
                                {/* <li className="nav-item ml-lg-3 pl-lg-3">
                                    <a className={this.state.currentPath === '/contact.html' ? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"} href="contact.html">Contact Us</a>
                                </li> */}
                              </ul>
                          </div>
                      </div>
                  </nav>
              </header>
          </div>
        );
    }
}

export default Header;
