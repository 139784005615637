function openModal(number) {
    // Get the modal
    const modal = document.getElementById(`modal-${number}`);
    // Get the image and insert it inside the modal - use its "alt" text as a caption
    const img = document.getElementById(`project-${number}`);
    const modalImg = document.getElementById(`modal-img-${number}`);
    const captionText = document.getElementById(`caption-${number}`);
    modal.style.display = "block";
    modalImg.src = img.src;
    captionText.innerHTML = img.alt;
}

function closeModal(number) {
    const modal = document.getElementById(`modal-${number}`);
    // When the user clicks on <span> (x), close the modal
    modal.style.display = "none";
}

function openLinkExternally(link) {
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', link);
    linkElement.setAttribute('target', '_blank');
    linkElement.click();
}

function goToSunsynkSlide(slideIndex) {
    const sunsynkSlides = document.getElementsByClassName("sunsynkSlide");
    const sunsynkSlidesLength = sunsynkSlides.length;
    const sunsynkSlideDots = document.getElementsByClassName("sunsynkSlideDot");
    if (slideIndex > sunsynkSlidesLength) { slideIndex = 1 }
    if (slideIndex < 1) { slideIndex = sunsynkSlidesLength }
    for (let i = 0; i < sunsynkSlidesLength; i++) {
        sunsynkSlides[i].style.display = "none";
    }
    for (let i = 0; i < sunsynkSlideDots.length; i++) {
        sunsynkSlideDots[i].className = sunsynkSlideDots[i].className.replace(" active", "");
    }
    sunsynkSlides[slideIndex - 1].style.display = "block";
    sunsynkSlideDots[slideIndex - 1].className += " active";
}

function goToNextSunsynkSlide(slideIndex) {
    goToSunsynkSlide(slideIndex + 1);
}

function goToPreviousSunsynkSlide(slideIndex) {
    goToSunsynkSlide(slideIndex - 1);
}

function setCurrentSunsynkSlide(slideIndex) {
    goToSunsynkSlide(slideIndex);
}

function scrollElementIntoView(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth',
        });
    }
}

module.exports = {
    openModal,
    closeModal,
    openLinkExternally,
    goToSunsynkSlide,
    goToNextSunsynkSlide,
    goToPreviousSunsynkSlide,
    setCurrentSunsynkSlide,
    scrollElementIntoView
}