/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import heroImage3 from './images/hero-3.jpeg';
import igIcon from './images/ig-favicon.png';

class Contact extends Component {
  constructor(props){
    super(props);
    this.state = { captchaFailed: false, messageSent: null, firstName: '', lastName: '', email: '', serviceType: '', message: '' };
    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onServiceTypeChange = this.onServiceTypeChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(document.location.search);
    const messageSent = params.get("messageSent") === 'true' ? true : params.get("messageSent") === 'false' ? false : null;
    this.setState({ messageSent: messageSent });
  }

  onFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }
  onLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }
  onEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  onServiceTypeChange(e) {
    this.setState({ serviceType: e.target.value });
  }
  onMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  clearForm() {
    this.setState({ firstName: '', lastName: '', email: '', serviceType: '', message: '' });
  }

  render(){
    let notification = '';
    if (this.state.messageSent === true){
      notification = (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Message Sent!</strong> We will be in touch as soon as possible.
          <button type="button" className="close py-1" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
    if (this.state.messageSent === false){
      notification = (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Message Failed To Send!</strong> Please email us directly by <a href="mailto:enquiries@tabothi.co.za" className="alert-link">clicking here</a>.
          <button type="button" className="close py-1" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
    const captchaNotification = this.state.captchaFailed ? 
    (
      <div className="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>reCAPTCHA Failed!</strong> Please fill in the form below and try again or email us directly by <a href="mailto:enquiries@tabothi.co.za" className="alert-link">clicking here</a>.
        <button type="button" className="close py-1" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    ) : ``;
    return (
      <div>
        <section className="hero bg-cover bg-position py-4" style={{backgroundImage: `url(${heroImage3})`}}>
          <div className="container py-5 index-forward text-white">
            <h1>Contact Us</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-none mb-0 p-0">
                <li className="breadcrumb-item pl-0"><a href="/"> <i className="fas fa-home mr-2"></i>Home</a></li>
                <li className="breadcrumb-item" aria-current="page">Contact Us</li>
              </ol>
            </nav>
          </div>
        </section>
        <section className="py-5 shadow-sm index-forward" id='topOfNotifications'>
          {/* Contact Form Notifications */}
          {notification}
          {captchaNotification}
          <div className="container py-5">
            <header>
              <h2><span className="text-primary">Contact Us</span></h2>
              <p className="mb-5 text-muted">Ready to transition to Solar Energy fill in the form below.</p>
            </header>
            <div className="row">
              <div className="col-lg-7 mb-5 mb-lg-0">
                <div className="p-4 p-lg-5 bg-white shadow round-border">
                  <form className="needs-validation" action="SendMessage.php" method="POST" noValidate>
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <input className="form-control" type="text" name="firstName" id="firstName" onChange={this.onFirstNameChange} value={this.state.firstName} placeholder="First Name"
                          required />
                        <div className="invalid-feedback">
                          Please enter your First Name.
                        </div>
                      </div>
                      <div className="form-group col-lg-6">
                        <input className="form-control" type="text" name="lastName" id="lastName" onChange={this.onLastNameChange} value={this.state.lastName} placeholder="Last Name"
                          required />
                        <div className="invalid-feedback">
                          Please enter your Last Name.
                        </div>
                      </div>
                      <div className="form-group col-lg-12">
                        <input className="form-control" type="email" name="email" id="email" onChange={this.onEmailChange} value={this.state.email} placeholder="Email Address" required />
                        <div className="invalid-feedback">
                          Please enter a valid email address with a @ sign.
                        </div>
                      </div>
                      <div className="form-group col-lg-12">
                        <select className="form-control" name="serviceType" id="serviceType" onChange={this.onServiceTypeChange} value={this.state.serviceType} required>
                          <option value="">-- Please Select A Service Type --</option>
                          <option value="Sales">Sales</option>
                          <option value="System Maintenance">System Maintenance</option>
                          <option value="Warranty Claim">Warranty Claim</option>
                        </select>
                        <div className="invalid-feedback">
                          Please select a Service Type.
                        </div>
                      </div>
                      <div className="form-group col-lg-12">
                        <textarea className="form-control" name="message" id="message" rows="5" onChange={this.onMessageChange} value={this.state.message} maxLength="500"
                          placeholder="Leave your message" required></textarea>
                        <p className="text-small text-muted mt-1">Only 500 Characters Max Are Allowed In Your Message.</p>
                        <div className="invalid-feedback">
                          Please enter a message.
                        </div>
                      </div>
                      <div className="form-group col-lg-12">
                        <p className="text-muted">By submitting this form you agree that you have read and agree with our <a
                            href="privacy-policy">
                            Privacy Policy</a></p>
                        <button className="btn btn-outline-primary" type="submit">Submit Now</button>
                      </div>
                    </div>
                    <div className="border-top"></div>
                    <p className="text-xsm mt-3 mb-0 text-center" style={{color: 'rgb(175, 175, 175)'}}>
                      Disclaimer: Under normal circumstances and due to our market segmentation strategy, product offering and
                      high
                      levels of specialisation, system design proposals and quotations are only issued after completing a
                      7-day detailed load profiling and data analysis process, terms and conditions apply.
                    </p>
                    {/* Recaptcha code */}
                    <script type="text/javascript"
                      src="https://www.google.com/recaptcha/api.js?render=6LdENKMfAAAAAC8ERnS9rdaWSHSkxR45l5-8t3N2">
                    </script>
                  </form>
                </div>
              </div>
              <div className="col-lg-4 pr-0">
                <h3>Have a question?</h3>
                <p className="text-muted mb-5">If you got any questions please do not hesitate to send us a message or call.</p>
                <ul className="list-unstyled">
                  <li className="d-flex mb-3">
                    <div className="icon bg-primary"><i className="fas fa-envelope text-white fa-fw"></i></div>
                    <div className="ml-3">
                      <h5 className="mb-0">Email</h5>
                      <p className="text-muted text-small"><a className="underline-on-hover" href="mailto:enquiries@tabothi.co.za"
                          target="_blank" rel="noreferrer">enquiries@tabothi.co.za</a></p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon bg-primary"><i className="fas fa-phone text-white fa-fw"></i></div>
                    <div className="ml-3">
                      <h5 className="mb-0">Phone</h5>
                      <p className="text-muted text-small"><a className="underline-on-hover" href="tel:+27835770881">+27 83 577
                          0881</a></p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="icon bg-primary"><i className="fab fa-facebook-f text-white fa-fw"></i></div>
                    <div className="ml-3">
                      <h5 className="mb-0">Facebook</h5>
                      <p className="text-muted text-small">
                        <a className="underline-on-hover" target="_blank" rel="noreferrer" 
                          href="https://www.facebook.com/profile.php?id=61561778347001">
                          Tabothi Energy Solutions
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-3">
                    <div className="no-border">
                      <img src={igIcon} alt='Instagram Logo' className='img-fluid' width="45" />
                    </div>
                    {/* <div className="icon bg-primary"><i className="fab fa-instagram text-white fa-fw"></i></div> */}
                    <div className="ml-3">
                      <h5 className="mb-0">Instagram</h5>
                      <p className="text-muted text-small">
                        <a className="underline-on-hover" target="_blank" rel="noreferrer" 
                          href="https://www.instagram.com/nkomo.reuben">
                          Visit our Instagram
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
