/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import solarPanelImage6 from './images/solar-panel-6.jpeg';
import heroImage1 from './images/hero-1.jpeg';
import heroImage4 from './images/hero-4.jpeg';

class About extends Component {
  render(){
    return (
      <div>
        {/* Hero Slider */}
        <section className="hero bg-cover bg-position py-4" style={{backgroundImage: `url(${heroImage1})`}}>
          <div className="container py-5 index-forward text-white">
            <h1>About Us</h1>
            {/* Breadcrumb */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-none mb-0 p-0">
                <li className="breadcrumb-item pl-0"><a href="/"> <i className="fas fa-home mr-2"></i>Home</a></li>
                <li className="breadcrumb-item" aria-current="page">About Us</li>
              </ol>
            </nav>
          </div>
        </section>
        <section className="py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-5 my-5">
                <div className="pt-1 bg-primary rounded"><img className="img-fluid rounded" src={solarPanelImage6} alt=""/>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="h6 mb-1 text-uppercase text-primary mb-3">About Tabothi Energy Solutions (TES)</p>
                <h2 className="mb-2">Our Philosophy</h2>
                <p className="text-small text-muted">Is anchored on three principles:</p>
                <ol className="col ml-3 mb-4">
                  <li className="text-small text-muted mb-2">Let us see the sun differently, it is a source of lifetime
                    energy</li>
                  <li className="text-small text-muted mb-2">Every roof has the potential (if enabled) to convert solar
                    energy into electrical energy</li>
                  <li className="text-small text-muted mb-2">Individual households and business have the potential to transition
                    to energy independence.</li>
                </ol>
                <div className="border-top"></div>
                <h2 className="mt-4">Our Goal</h2>
                <p className="text-small text-muted mb-4 mt-1">Is to design and install well thought through solar and backup
                  energy
                  systems
                  that meets our client energy needs within budget and shortest period possible.</p>
                <div className="border-top"></div>
                <h2 className="mt-4">What We Believe</h2>
                <p className="text-small text-muted mb-4 mt-1">We strongly believe that energy independence and security is not
                  just a function of investment in a solar solution but a combination of strategic investment, technology
                  optimisation and behavioural changes. When implemented together they reduce system costs, ultimately
                  resulting in a higher degree of autonomy.</p>
                <ul className="list-inline py-4 border-top border-bottom d-flex align-items-center">
                  <li className="list-inline-item px-4 mr-0">
                    <h5 className="mb-0">Reuben Nkomo</h5>
                    <p className="small font-weight-normal text-muted mb-0">Founder and CEO</p>
                  </li>
                  <li className="list-inline-item pl-4 border-left">
                    <h6 className="mb-0">Have a Question? Contact Us at</h6>
                    <p className="small font-weight-normal text-muted mb-0"><a href="mailto:enquiries@tabothi.co.za"
                        style={{color: 'inherit', textDecoration: 'underline'}}
                        className="remove-on-hover">enquiries@tabothi.co.za</a> or <a href="tel:+27835770881"
                        style={{color: 'inherit', textDecoration: 'underline'}} className="remove-on-hover">+27 83 577 0881</a></p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="py-5">
          <div className="container py-5">
            <h2>Product Range</h2>
            <p className="text-muted mb-5">It is a fact that not all products are created equal, as such, we specialise on three
              inverters/solar charger controller brands and three battery storage systems from reputable distributors and
              manufacturers. We believe specialisation is key to quality systems and optimal performance, hence our decision
              to
              focus on products with proven compatibilities namely:</p>
            <div className="row">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <div className="accordion" id="productRangeAccordion">
                  <div className="card rounded-0 border-0 mb-1 round-border">
                    <div className="card-header p-0 border-0 bg-white rounded-0" id="headingOne">
                      <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block" data-toggle="collapse"
                          href="#firstCollapse" aria-expanded="true" aria-controls="firstCollapse">
                          Inverters &amp; Solar Chargers
                        </a></h6>
                    </div>
                    <div className="collapse show" id="firstCollapse" aria-labelledby="headingOne"
                      data-parent="#productRangeAccordion">
                      <div className="card-body">
                        <ul>
                          <li className="text-muted">Goodwe</li>
                          <li className="text-muted">Fronius</li>
                          <li className="text-muted">Victron</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card rounded-0 border-0 mb-1 round-border">
                    <div className="card-header p-0 border-0 bg-white rounded-0" id="headingTwo">
                      <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block collapsed" data-toggle="collapse"
                          href="#secondCollapse" aria-expanded="false" aria-controls="secondCollapse">
                          Energy Storage Systems
                        </a></h6>
                    </div>
                    <div className="collapse" id="secondCollapse" aria-labelledby="headingTwo"
                      data-parent="#productRangeAccordion">
                      <div className="card-body">
                        <ul>
                          <li className="text-muted">Freedom Won - Lite</li>
                          <li className="text-muted">Blue Nova – RacPower DU</li>
                          <li className="text-muted">Pylon Tech</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <ul className="list-unstyled">
                  <li className="d-flex">
                    <i className="fas fa-arrow-up text-primary text-xl"></i>
                    <div className="ml-3">
                      <h5>Here at Tabothi Energy Solutions (TES)</h5>
                      <p className="text-small text-muted">Our reputation and business sustainability are intertwined between the
                        product capabilities and our capacity to design and install them optimally.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container py-5">
            <h2>Frequently Asked Questions</h2>
            <p className="text-muted mb-5">See our most common asks.</p>
            <div className="row">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <div className="accordion" id="faqAccordion">
                  <div className="card rounded-0 border-0 mb-1 round-border">
                    <div className="card-header p-0 border-0 bg-white rounded-0" id="headingOne">
                      <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block" data-toggle="collapse"
                          href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          How Do I Get In Contact?
                        </a></h6>
                    </div>
                    <div className="collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#faqAccordion">
                      <div className="card-body">
                        <p className="text-small text-muted">Just contact us for services using our <a href="contact.html">contact
                            form</a> for any questions <a href="mailto:enquiries@tabothi.co.za">mail</a> or <a
                            href="tel:+27835770881">call</a>
                          us.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card rounded-0 border-0 mb-1 round-border">
                    <div className="card-header p-0 border-0 bg-white rounded-0" id="headingTwo">
                      <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block collapsed" data-toggle="collapse"
                          href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          What Is Your Business Process
                        </a></h6>
                    </div>
                    <div className="collapse" id="collapseTwo" aria-labelledby="headingTwo" data-parent="#faqAccordion">
                      <div className="card-body">
                        <ol>
                          <li className="text-muted text-sm mb-4">
                            Load profiling and data analysis to determine:
                            <ul>
                              <li>Base load</li>
                              <li>Maximum load, frequency, and duration</li>
                              <li>Opportunities for alternative source of energy</li>
                            </ul>
                          </li>
                          <li className="text-muted text-sm mb-4">
                            Load shifting and recommended behavioural changes
                          </li>
                          <li className="text-muted text-sm mb-4">
                            Comparative system designs and alternative technological proposals
                          </li>
                          <li className="text-muted text-sm mb-4">
                            Quality installation and commissioning
                          </li>
                          <li className="text-muted text-sm">
                            Monitoring, Maintenance, and recommended upgrades.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="card rounded-0 border-0 round-border">
                    <div className="card-header p-0 border-0 bg-white rounded-0" id="headingThree">
                      <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block collapsed border-bottom"
                          data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          What Services Do You Offer?
                        </a></h6>
                    </div>
                    <div className="collapse" id="collapseThree" aria-labelledby="headingThree" data-parent="#faqAccordion">
                      <div className="card-body">
                        <ul>
                          <li className="text-muted">Grid Tied Systems</li>
                          <li className="text-muted">Grid Interactive Systems</li>
                          <li className="text-muted">Backup Solutions</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* Divider Section */}
        <section className="bg-cover bg-center" style={{backgroundImage: `url(${heroImage4})`}}>
          <div className="primary-overlay py-5">
            <div className="overlay-content">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7 text-center text-lg-left mb-4 mb-lg-0">
                    <h2 className="text-white mb-2">
                      Looking to transition to Solar Energy?
                    </h2>
                    <p className="text-white mb-0">Tabothi Energy Solutions Is Here To Help You</p>
                  </div>
                  <div className="col-lg-3 ml-auto text-center text-lg-right"><a className="btn btn-outline-light"
                      href="contact.html">Contact Us Now</a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default About;
