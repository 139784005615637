/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import heroImage5 from './images/hero-5.jpeg';
import heroImage4 from './images/hero-4.jpeg';
import commercialProjectImage1 from './images/commercial-image-1.jpg';
import commercialProjectImage2 from './images/commercial-image-2.jpg';
import commercialProjectImage3 from './images/commercial-image-3.jpg';
import commercialProjectImage4 from './images/commercial-image-4.jpg';
import commercialProjectImage5 from './images/commercial-image-5.jpg';
import commercialProjectImage6 from './images/commercial-image-6.jpg';
import { openModal, closeModal } from './js';
import './Projects.css';

const images = [
    commercialProjectImage1,
    commercialProjectImage2,
    commercialProjectImage3,
    commercialProjectImage4,
    commercialProjectImage5,
    commercialProjectImage6
];

class CommercialProjects extends Component {
    render(){
        return (
          <div>
            {/* Hero Slider */}
            <section className="hero bg-cover bg-position py-4" style={{backgroundImage: `url(${heroImage4})`}}>
                <div className="container py-5 index-forward text-white">
                    <h1>Our Commercial Projects</h1>
                    {/* <!-- Breadcrumb--> */}
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb bg-none mb-0 p-0">
                            <li className="breadcrumb-item pl-0"><a href="/"> <i className="fas fa-home mr-2"></i>Home</a></li>
                            <li className="breadcrumb-item">Projects</li>
                            <li className="breadcrumb-item" aria-current="page">Commercial Projects</li>
                        </ol>
                    </nav>
                </div>
            </section>
            {/* Services Section */}
            <section className="py-5">
                <div className="container py-5 text-center">
                    <header className="mb-5">
                        <div className="row">
                            <div className="col-lg-7 mx-auto">
                                <p className="h6 text-uppercase text-primary mb-0">Our Commercial Project Gallery</p>
                                <h3>Description:</h3>
                                <h5>
                                    <li>PV Array: 295kWp</li>
                                    <li>PV Modules: 500 x 590W LONGi Solar</li>
                                    <li>Grid-Tie Inverter: 2 x 125kW Sungrow string inverters</li>
                                    <li>Roof Mounting: Lumax - IBR mini rails</li>
                                </h5>
                            </div>
                        </div>
                    </header>
                    <div className="row align-items-stretch">
                        <div className="card-columns">
                        {images.map((image, index) => 
                            <div className="project-img" key={`index-${index}`}>
                                <img className="modal-img img-fluid" id={'project-' + index} src={image} onClick={() => { openModal(index) }} />
                                {/* The Modal */}
                                <div id={'modal-' + index} className="modal">

                                    {/* The Close Button */}
                                    <span className="close" onClick={() => { closeModal(index) }} >&times;</span>

                                    {/* Modal Content (The Image) */}
                                    <img className="modal-content" id={'modal-img-' + index} />

                                    {/* Modal Caption (Image Text) */}
                                    <div id={'caption-' + index} className="caption"></div>
                                </div>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </section>
            {/* Divider Section */}
            <section className="bg-cover bg-center" style={{backgroundImage: `url(${heroImage5})`}}>
                <div className="primary-overlay py-5">
                    <div className="overlay-content">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7 text-center text-lg-left mb-4 mb-lg-0">
                                    <h2 className="text-white mb-2">
                                    Looking to transition to Solar Energy?
                                    </h2>
                                    <p className="text-white mb-0">Tabothi Energy Solutions Is Here To Help You</p>
                                </div>
                                <div className="col-lg-3 ml-auto text-center text-lg-right"><a className="btn btn-outline-light"
                                    href="contact.html">Contact Us Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          </div>
        );
    }
}

export default CommercialProjects;
